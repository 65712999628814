import ApiService from '@/services/core/api.service'

const PaymentRequestService = {
  async get (query = '') {
    return ApiService.get(`financial-operation-manager/payment-approvals?${query}`)
  },

  async show (id) {
    return ApiService.get(`financial-operation-manager/payment-approvals/${id}`)
  },

  async post (payload) {
    return ApiService.post('financial-operation-manager/payment-approvals', payload)
  },

  async put (payload) {
    return ApiService.put(`financial-operation-manager/payment-approvals/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`financial-operation-manager/payment-approvals/${payload.id}`)
  }
}

export default PaymentRequestService
