import ApiService from '@/services/core/api.service'

const QAPService = {
  async get (query = '') {
    return ApiService.get(`tax-accountant/reports/qap?${query}`)
  },

  async export (query = '') {
    return ApiService.get(`tax-accountant/exports/qap?${query}`)
  }
}

export default QAPService
