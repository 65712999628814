import ApiService from '@/services/core/api.service'

const PendingService = {
  async get (query = '') {
    return ApiService.get(`disbursement-associate/document-printing?${query}`)
  },

  async show (id) {
    return ApiService.get(`disbursement-associate/document-printing/${id}`)
  },

  async post (payload) {
    return ApiService.post('disbursement-associate/document-printing', payload)
  },

  async put (payload) {
    return ApiService.put(`disbursement-associate/document-printing/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`disbursement-associate/document-printing/${payload.id}`)
  }
}

export default PendingService
