import ApiService from '@/services/core/api.service'

const DocumentTypeService = {
  async get (query = '') {
    return ApiService.get(`tax-accountant/document-types?${query}`)
  },

  async post (payload) {
    return ApiService.post('tax-accountant/document-types', payload)
  },

  async put (payload) {
    return ApiService.put(`tax-accountant/document-types/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`tax-accountant/document-types/${payload.id}`)
  }
}

export default DocumentTypeService
