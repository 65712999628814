function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/fom/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const FOMRoutes = [
  {
    path: '/fom/dashboard',
    component: view('Dashboard'),
    name: 'fom.dashboard',
    authorizedRole: 'fom'
  },
  // PENDING APPROVALS
  {
    path: '/fom/approvals/payment-requests/:fom?',
    component: view('approvals/payment-requests/Base'),
    name: 'fom.approvals.payment-requests',
    authorizedRole: 'fom',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Request Approvals',
          active: true
        }
      ]
    }
  }
]

export default FOMRoutes
