import ApiService from '@/services/core/api.service'

const FiscalYearService = {
  async get (query = '') {
    return ApiService.get(`budget-officer/fiscal-years?${query}`)
  },

  async post (payload) {
    return ApiService.post('budget-officer/fiscal-years', payload)
  },

  async put (payload) {
    return ApiService.put(`budget-officer/fiscal-years/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`budget-officer/fiscal-years/${payload.id}`)
  }
}

export default FiscalYearService
