import ApiService from '@/services/core/api.service'

const BusinessPartnerService = {
  async get (query = '') {
    return ApiService.get(`tax-accountant/business-partners?${query}`)
  },

  async post (payload) {
    return ApiService.post('tax-accountant/business-partners', payload)
  },

  async put (payload) {
    return ApiService.put(`tax-accountant/business-partners/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`tax-accountant/business-partners/${payload.id}`)
  }
}

export default BusinessPartnerService
