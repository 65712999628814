import ApiService from '@/services/core/api.service'

const ListService = {
  async getActualCenters ($query = '') {
    return ApiService.get(`shared/list/actual-centers?${$query}`)
  },

  async getApprovers ($query = '') {
    return ApiService.get(`shared/list/approvers?${$query}`)
  },

  async getBanks ($query = '') {
    return ApiService.get(`shared/list/banks?${$query}`)
  },

  async getBankAccounts ($query = '') {
    return ApiService.get(`shared/list/bank-accounts?${$query}`)
  },

  async getBudgetApprovers ($query = '') {
    return ApiService.get(`shared/list/budget-approvers?${$query}`)
  },

  async getBudgetCenters ($query = '') {
    return ApiService.get(`shared/list/budget-centers?${$query}`)
  },

  async getBusinessPartners ($query = '') {
    return ApiService.get(`shared/list/business-partners?${$query}`)
  },

  async getBusinessTaxes ($query = '') {
    return ApiService.get(`shared/list/business-taxes?${$query}`)
  },

  async getCustomList ($query = '') {
    return ApiService.get(`shared/list/custom-list?${$query}`)
  },

  async getCustomerTypes ($query = '') {
    return ApiService.get(`shared/list/customer-types?${$query}`)
  },

  async getFiscalYearStatuses ($query = '') {
    return ApiService.get(`shared/list/fiscal-year-statuses?${$query}`)
  },

  async getItems ($query = '') {
    return ApiService.get(`shared/list/items?${$query}`)
  },

  async getItemCenters ($query = '') {
    return ApiService.get(`shared/list/item-centers?${$query}`)
  },

  async getModeOfPayments ($query = '') {
    return ApiService.get(`shared/list/mode-of-payments?${$query}`)
  },

  async getPartnerTypes ($query = '') {
    return ApiService.get(`shared/list/partner-types?${$query}`)
  },

  async getRoles ($query = '') {
    return ApiService.get(`shared/list/roles?${$query}`)
  },

  async getTaxCodes ($query = '') {
    return ApiService.get(`shared/list/tax-codes?${$query}`)
  },

  async getTaxStatuses ($query = '') {
    return ApiService.get(`shared/list/tax-statuses?${$query}`)
  },

  async getVendorTypes ($query = '') {
    return ApiService.get(`shared/list/vendor-types?${$query}`)
  },

  async getReportPeriods ($query = '') {
    return ApiService.get(`shared/list/report-periods?${$query}`)
  },

  async getSystemOptions ($query = '') {
    return ApiService.get(`shared/list/system-options?${$query}`)
  }
}

export default ListService
