import ApiService from '@/services/core/api.service'

const PendingService = {
  async get (query = '') {
    return ApiService.get(`disbursement-associate/payment-releasing?${query}`)
  },

  async post (payload) {
    return ApiService.post('disbursement-associate/payment-releasing', payload)
  },

  async put (payload) {
    return ApiService.put(`disbursement-associate/payment-releasing/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`disbursement-associate/payment-releasing/${payload.id}`)
  }
}

export default PendingService
