import ApiService from '@/services/core/api.service'

const ItemCenterService = {
  async get (query = '') {
    return ApiService.get(`budget-officer/items?${query}`)
  },

  async post (payload) {
    return ApiService.post('budget-officer/items', payload)
  },

  async put (payload) {
    return ApiService.put(`budget-officer/items/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`budget-officer/items/${payload.id}`)
  }
}

export default ItemCenterService
