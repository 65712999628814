import AdminRoutes from './admin-routes'
import AuditRoutes from './audit-routes'
import BORoutes from './bo-routes'
import CMORoutes from './cmo-routes'
import DAssociateRoutes from './d-associate-routes'
import DHeadRoutes from './d-head-routes'
import ESRoutes from './es-routes'
import FOMRoutes from './fom-routes'
import RAMRoutes from './ram-routes'
import TARoutes from './ta-routes'
import UserRoutes from './user-routes'
import SharedRoutes from './shared-routes'

const allRoutes = []

export default allRoutes.concat(
  AdminRoutes,
  AuditRoutes,
  BORoutes,
  CMORoutes,
  DAssociateRoutes,
  DHeadRoutes,
  ESRoutes,
  FOMRoutes,
  RAMRoutes,
  SharedRoutes,
  TARoutes,
  UserRoutes
)
