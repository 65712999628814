import ApiService from '@/services/core/api.service'

const TaxCodeService = {
  async get (query = '') {
    return ApiService.get(`tax-accountant/tax-codes?${query}`)
  },

  async post (payload) {
    return ApiService.post('tax-accountant/tax-codes', payload)
  },

  async put (payload) {
    return ApiService.put(`tax-accountant/tax-codes/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`tax-accountant/tax-codes/${payload.id}`)
  }
}

export default TaxCodeService
