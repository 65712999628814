import ApiService from '@/services/core/api.service'

const PaymentVoucherService = {
  async get (query = '') {
    return ApiService.get(`disbursement-associate/payment-vouchers?${query}`)
  },

  async post (payload) {
    return ApiService.post('disbursement-associate/payment-vouchers', payload)
  },

  async put (payload) {
    return ApiService.put(`disbursement-associate/payment-vouchers/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`disbursement-associate/payment-vouchers/${payload.id}`)
  }
}

export default PaymentVoucherService
