import ApiService from '@/services/core/api.service'

const PaymentNatureService = {
  async get (query = '') {
    return ApiService.get(`tax-accountant/payment-natures?${query}`)
  },

  async post (payload) {
    return ApiService.post('tax-accountant/payment-natures', payload)
  },

  async put (payload) {
    return ApiService.put(`tax-accountant/payment-natures/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`tax-accountant/payment-natures/${payload.id}`)
  }
}

export default PaymentNatureService
