import ApiService from '@/services/core/api.service'

const AttachmentService = {

  async uploadAttachments (payload, progressCallback = null) {
    return ApiService.post(
      'shared/attachments/payment-request/upload',
      payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent
          const progress = Math.round((loaded * 100) / total).toFixed(2)
          if (typeof progressCallback === 'function') {
            progressCallback(progress, loaded, total)
          }
        }
      }
    )
  },

  async retrieveBranding (name = '') {
    // file, file_path, file_list
    return ApiService.get(`shared/attachments/payment-request/retrieve-branding?file_name=${name}`, {
      responseType: 'blob'
    }) // responseType: 'blob'
  },

  async retrieveAttachments (path = '', name = '', storage = 'attachment') {
    // file, file_path, file_list
    return ApiService.get(`shared/attachments/payment-request/retrieve-${storage}?attachment_path=${path}&file_name=${name}`, {
      responseType: 'blob'
    }) // responseType: 'blob'
  },

  async removeAttachments (payload) {
    // file, file_path, file_list
    return ApiService.delete('shared/attachments/payment-request/remove', {
      data: payload
    })
  },

  async removeAllAttachments (payload) {
    return ApiService.delete('shared/attachments/payment-request/remove-all', {
      data: payload
    })
  }

}

export default AttachmentService
