import store from '@/store'

export default async (to, from, next) => {
  const user = store.getters['auth/user']
  if (user.role !== 'dh') {
    next({ name: `${user.role}.dashboard` })
  } else {
    next()
  }
}
