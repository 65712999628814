import { AxiosError } from 'axios'

const ErrorService = {
  transformError (error) {
    if (error.code === 422) {
      return new AxiosError(error.message.errors, error.code)
    }

    if (error.message?.message) {
      return new AxiosError(error.message.message, error.code)
    }

    return new AxiosError(error.message, error.code)
  }
}

export { AxiosError, ErrorService }
