import ApiService from '@/services/core/api.service'

const HistoryService = {
  async get (query = '') {
    return ApiService.get(`executive-secretary/reports/check-approvals?${query}`)
  },

  async post (payload) {
    return ApiService.post('executive-secretary/reports/check-approvals', payload)
  },

  async put (payload) {
    return ApiService.put(`executive-secretary/reports/check-approvals/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`executive-secretary/reports/check-approvals/${payload.id}`)
  }
}

export default HistoryService
