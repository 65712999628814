function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/bo/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const BORoutes = [
  {
    path: '/bo/dashboard',
    component: view('Dashboard'),
    name: 'bo.dashboard',
    authorizedRole: 'bo'
  },
  {
    path: '/bo/maintenances/fiscal-years',
    component: view('FiscalYears'),
    name: 'bo.maintenances.fiscal-years',
    authorizedRole: 'bo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Fiscal Years',
          active: true
        }
      ]
    }
  },
  {
    path: '/bo/maintenances/budget-centers',
    component: view('BudgetCenters'),
    name: 'bo.maintenances.budget-centers',
    authorizedRole: 'bo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Budget Centers',
          active: true
        }
      ]
    }
  },
  {
    path: '/bo/maintenances/items',
    component: view('Items'),
    name: 'bo.maintenances.items',
    authorizedRole: 'bo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Items',
          active: true
        }
      ]
    }
  },
  {
    path: '/bo/maintenances/item-centers',
    component: view('item-centers/Base'),
    name: 'bo.maintenances.item-centers',
    authorizedRole: 'bo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Item Centers',
          active: true
        }
      ]
    }
  }
]

export default BORoutes
