import ApiService from '@/services/core/api.service'

const VatDetailService = {
  async get (query = '') {
    return ApiService.get(`tax-accountant/reports/vat-details?${query}`)
  },

  async export (query = '') {
    return ApiService.get(`tax-accountant/exports/vat-details?${query}`)
  }
}

export default VatDetailService
