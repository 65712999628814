import ApiService from '@/services/core/api.service'

const BudgetService = {
  async get (query = '') {
    return ApiService.get(`budget-officer/budgets?${query}`)
  },

  async post (payload) {
    return ApiService.post('budget-officer/budgets', payload)
  },

  async put (payload) {
    return ApiService.put(`budget-officer/budgets/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`budget-officer/budgets/${payload.id}`)
  }
}

export default BudgetService
