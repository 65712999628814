import ApiService from '@/services/core/api.service'

const BudgetCenterService = {
  async get (query = '') {
    return ApiService.get(`budget-officer/budget-centers?${query}`)
  },

  async post (payload) {
    return ApiService.post('budget-officer/budget-centers', payload)
  },

  async put (payload) {
    return ApiService.put(`budget-officer/budget-centers/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`budget-officer/budget-centers/${payload.id}`)
  }
}

export default BudgetCenterService
