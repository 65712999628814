import ApiService from '@/services/core/api.service'

const BankAccountService = {
  async get (query = '') {
    return ApiService.get(`tax-accountant/bank-accounts?${query}`)
  },

  async post (payload) {
    return ApiService.post('tax-accountant/bank-accounts', payload)
  },

  async put (payload) {
    return ApiService.put(`tax-accountant/bank-accounts/${payload.id}`, payload)
  }
}

export default BankAccountService
