function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/ram/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RAMRoutes = [
  {
    path: '/ram/dashboard',
    component: view('Dashboard'),
    name: 'ram.dashboard',
    authorizedRole: 'ram'
  },
  // PENDING APPROVALS
  {
    path: '/ram/approvals/payment-requests/:ram?',
    component: view('approvals/payment-requests/Base'),
    name: 'ram.approvals.payment-requests',
    authorizedRole: 'ram',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Request Approvals',
          active: true
        }
      ]
    }
  }
]

export default RAMRoutes
